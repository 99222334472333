@import '../../styles/vars.scss';

.select_wrapper {
  position: relative;
}

.option_list {
  :global(.MuiPaper-root) {
    padding: 8px 10px 8px 8px;
    border-radius: 8px;
    box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.1);

    @media (max-width: $mobile) {
      top: 230px !important;
    }
  }
}

.not_clickable {
  pointer-events: none;
}

.menu_wrapper {
  z-index: 1;
}

.menu_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
