.container {
  width: 100%;
  border-radius: 8px;
  transition: background-color 150ms ease-in-out;

  &_hover {
    &:hover {
      background-color: #f3f6ff;
    }
  }
}

.search_input {
  width: 100%;
}

.task_performer_container {
  width: 100%;
  border-radius: 8px;
}

.search_container {
  padding: 8px;
  border-bottom: 1px solid #efefef;
}

.options {
  max-height: 345px;
  padding: 0 8px 8px 8px;
  padding-top: 12px;
  overflow: scroll;
  &[data-is-lazy-processing='true'] {
    &::after {
      opacity: 0.3;
      animation: breathe 0.8s infinite alternate;
    }
  }
  &::after {
    opacity: 0;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.8s;
    border-bottom: 3px solid #7894ff;
  }

  .unassigned_user {
    margin-bottom: 15px;
    padding-bottom: 12px;
    border-bottom: 0.8px solid #ebeef0;
  }
}

.option_skeleton {
  width: 100%;
  height: 30px;
  transform: none;
  border-radius: 6px;
  margin-top: 8px;
}

.no_options_found {
  font-size: 14px;
  display: flex;
  justify-content: center;
  color: #515151;
}

.menu_container {
  padding: 0px;
  min-width: 255px;
  margin-top: 1px;
}

.skeleton {
  padding: 12px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  span {
    transform: none;
  }
}

.skeleton_item {
  height: 36px;
}

@keyframes breathe {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
