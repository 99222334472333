.option_container {
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  &:not(:first-child) {
    margin-top: 12px;
  }
  &:hover {
    .option_name {
      color: #0851fe;
    }
    .option_avatar {
      border-color: #7894ff;
    }
  }

  &.selected {
    cursor: default;
    .option_name {
      color: #0851fe;
      font-weight: 500;
    }
    .option_avatar {
      border-color: #7894ff;
      border-width: 1.5px;
    }
  }
}

.option_name {
  color: #5c668c;
  font-weight: 500;
  font-size: 14px;
  max-width: calc(100% - 55px);
}

.user_info_container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  .user_desc {
    width: fit-content;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
  }

  .user_desc_text {
    color: #5c668c;
    font-weight: 400;
  }

  .margin_right {
    margin-right: 4px;
  }
}
